<template>
  <div class="detail">
    <!-- <div class="realname-box" v-if="!auth && detail.tabId <= 3">
      根据国家政策需要提供实名信息
      <div class="button" @click="goUrl('/pages/ucenter/authentication/auth')">立即认证</div>
    </div> -->
    <div v-if="detail.tabId >= 3 && detail.paystatus === 'PAYED' && evalStatus == 1" class="float efloat">
      <div class="efloat-bg" />
      <div class="modal modalFade">
        <div class="emodal-title">
          <div>匿名评价</div>
          <div class="emodal-close app-ico-close" @click="closeEva" />
        </div>
        <div class="emodal-eva">
          <div class="title">您对本次取件服务是否满意</div>
          <div class="eva-desc">您的反馈会帮助我们提升服务质量</div>
          <div class="eva-items">
            <div class="eva-item" @click="orderEva('0')">
              <img src="https://cdn.kuaidi100.com/images/rebuild/image/eva/mad.png" />
              <div>不满意</div>
            </div>
            <div class="eva-item" @click="orderEva('1')">
              <img src="https://cdn.kuaidi100.com/images/rebuild/image/eva/smile.png" />
              <div>满意</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="evalStatus == 2" class="float">
      <div class="efloat-bg" />
      <div class="modal">
        <div class="emodal-title">
          <div class="modal-tcancle" @click="closeEva">取消</div>
          <div>匿名评价</div>
          <div class="modal-tcomplete" @click="subBadEva">完成</div>
        </div>
        <div class="emodal-eva">
          <div class="eva-bad">
            <img src="https://cdn.kuaidi100.com/images/rebuild/image/eva/mad.png" />
            <div>不满意</div>
          </div>
          <div class="evaAry-title">请选择您不满意的原因</div>
          <div class="evaAry">
            <div v-for="(item, index) in evaAry" :key="index" :class="[item.s ? 'ei-selected' : '']" class="evaAry-item" @click="setEvaComments(index)">{{ item.v }}</div>
          </div>
          <input v-model="sendNameChange" type="text" class="eva-input" maxlength="20" placeholder-class="eva-placeholder" placeholder="请输入其他原因" @input="evaReasonInput">
        </div>
      </div>
    </div>
    <div v-if="evalStatus == 3" class="float">
      <div class="efloat-bg" />
      <div class="modal">
        <div class="emodal-title">
          <div>匿名评价</div>
          <div class="emodal-close app-ico-close" @click="closeEva" />
        </div>
        <div class="emodal-eva">
          <div class="eva-bad">
            <img src="https://cdn.kuaidi100.com/images/rebuild/image/eva/mad.png" />
          </div>
          <div class="evaAry">
            <div v-for="(item, index) in evaAry" :key="index" class="evaAry-item">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showBigCode" class="float" @click="showBigCode = false">
      <div class="modal-code">
        <div class="code">{{ detail.gotcode }}</div>
        <div class="code-img">
          <img :src="`https://www.kuaidi100.com/twoCode.do?code=${detail.gotcode}&type=barcode&h=74&w=536`" />
        </div>
        <div class="tips mt30">取件码是快递员确认取件的唯一凭证，</div>
        <div class="tips">请在快递员取件后再告诉快递员，以免造成损失</div>
      </div>
    </div>
    <Modal v-model="isShowCodeTips" confirm-text="我知道了" type="alert" title="提示" z-index="100">
      取件码是快递员确认取件的唯一凭证，请在快递员取件后再告诉快递员，以免造成损失
    </Modal>
    <div class='kdnu-box' @click='toQueryNu(detail.kuaidiCom, detail.kuaidiNum)'
      v-if="JSON.stringify(detail) !== '{}' && detail.tabId >= 3 && detail.tabId != 4 && detail.tabId != 7"
      >
      <div>
        {{detail.comName}}单号： 
        <span v-if="detail.kuaidiNum">{{detail.kuaidiNum}}</span> 
        <span v-else style="color:#bebebe;">暂无，取件后显示</span>
        <img v-if="detail.kuaidiNum" @click.stop="copyExpid1(detail.kuaidiNum)" src="https://cdn.kuaidi100.com/images/rebuild/copy_nu.png">
        <div style="position: fixed;top:-9999px;left: -9999px;" class="copytxt1">{{detail.kuaidiNum}}</div>
      </div>
    </div>
    <div v-if="detail.tabId === 3" class="gcode-box">
      <div class="code-info">
        <div class="nu-value">
          <div class="label">取件码</div>
          <div class="code-value">{{ detail.gotcode }}</div>
        </div>
        <div class="code-help" @click="isShowCodeTips = true" />
      </div>
      <div v-if="!detail.gotaddr2" class="code-image" @click="showBigCode = true">
        <img :src="`https://www.kuaidi100.com/twoCode.do?code=${detail.gotcode}&type=barcode&h=74&w=536`" />
      </div>
    </div>
    <div v-if="JSON.stringify(detail) !== '{}'" class="ostatus-box" style="margin-bottom: 13rem;">
      <div class="ostatus-card">
        <div class="card-title">{{ detail.tabIdName }}</div>
        <div class="card-tips" 
          :class="[detail.tabId == 12 ? 'blue' : '']">{{ statusTips }}<span v-if="detail.disdoortime < 0 && detail.tabId <= 3" class="s-remind">已超时</span>
        </div>
        <div v-if="detail.statusDesc" class="card-desc">{{ detail.statusDesc }}</div>
        <div v-if="detail.tabId==1 && showWaitTime" class="card-time">已经等待
          <span class="time">{{ waitTime }}</span>
        </div>
        <div v-if="detail.tabId > 1" class="more-opt" @click="isMoreOpt = !isMoreOpt">
          <img
            :src="isMoreOpt ? 'https://cdn.kuaidi100.com/images/rebuild/image/iconClose.png':'https://cdn.kuaidi100.com/images/rebuild/image/moreOpt.png'"
          />
        </div>
        <div v-if="isMoreOpt" class="more-btn" @click="isMoreOpt = false">
          <div class="ico-guide" />
          <div class="item" @click="goUrl">帮助中心</div>
          <div v-if="detail.tabId <= 3" class="item" @click="goCancel">取消订单</div>
          <div
            v-if="detail.ppaystatus == 1 && (mktInfo || (courierinfo&&courierinfo.name))"
            class="item"
            @click="toComplain"
          >{{ detail.complaint == 'N' ? '投诉' : '投诉进度' }}</div>
        </div>
      </div>
      <div v-if="detail.tabId != 4 && detail.tabId != 7" class="cour-info">
        <div class="info-left">
          <div class="info-avatar">
            <img
              :src="mktInfo ? (mktInfo.logo ? mktInfo.logo : 'https://cdn.kuaidi100.com/images/rebuild/image1/courierAvatar.png'):'https://cdn.kuaidi100.com/images/rebuild/image1/icoKuaidi100Courier.png'"
            />
          </div>
          <div class="info-detail">
            <div class="name">{{ mktInfo ? mktInfo.mktName : '快递100优选快递员'}}</div>
          </div>
        </div>
        <div v-if="mktInfo" class="info-tel" @click="bindTelTap(mktInfo.phone)">
          <img src="https://cdn.kuaidi100.com/images/rebuild/image1/icoPhone.png" />
        </div>
      </div>
      <!--支付-->
      <div
        v-if="detail.price >0 && detail.paystatus == 'WAITPAY' && detail.wechatpayfail != 'Y' && detail.payStatusText != '扣款中'"
        class="pay-box"
      >
        <div class="pay-detail">
          <div class="d-num">需支付<span>{{ detail.price }}</span>元</div>
          <div class="d-td" @click="toPayDetail(1)">查看明细</div>
        </div>
        <div class="paybtn-s" @click="toPayDetail(1)">{{ detail.paywayen && detail.paywayen == 'WXAPP_PAYAFTER' ? '确认并' :'' }}支付</div>
      </div>
      <div
        v-if="(detail.wechatpayfail=='Y' || detail.payStatusText == '扣款中') && detail.tabId != 4"
        class="pay-box"
      >
        <div class="pay-detail">
          <div class="d-num d-num-w">
            <div>
              <span>{{ detail.price ? detail.price : detail.pprice }}</span>元扣款中</div>
            <div class="paybtn-tips" @click="showPayTips">
              <img src="https://cdn.kuaidi100.com/images/rebuild/image/ico_help.png" />如何支付？</div>
          </div>
          <div class="d-td" @click="toPayDetail(2)">查看明细</div>
        </div>
      </div>
      <!--评价-->
      <div v-if="detail.tabId >= 3 && detail.paystatus == 'PAYED'" class="eva-box select-item" @click="toEva">
        <div class="label">{{ detail.iseval == 0 ? '匿名评价本次取件服务' : '已匿名评价本次取件服务' }}</div>
        <div :class="[detail.iseval == 0? 'fcb' : 'fc3']" class="eva-status">{{ detail.iseval == 0 ? '未评价' : detail.evalmsg }}</div>
      </div>
      <!--详情-->
      <div v-if="!orderDetailShow && detail.tabId == 0" class="order-expand" @click="orderDetailShow = !orderDetailShow">
        <div>点击查看订单详情</div>
        <img src="https://cdn.kuaidi100.com/images/rebuild/image/expand.png" />
      </div>
      <div v-if="detail && (orderDetailShow|| detail.tabId != 0)" class="order-box">
        <div v-if="detail.payway == 'WXAPP_PAYAFTER' || detail.paywayen == 'WXAPP_PAYAFTER'" class="order-card-tip">本单用微信支付分下单并在快递员取件后结算</div>
        <div v-if="detail.tabId != 0" class="order-card">
          <div class="order-info">
            <div class="addr">{{ overText(detail.sendcity) }}</div>
            <div class="name">{{ overText(detail.sendName) }}</div>
          </div>
          <div class="order-status">
            <div :class="[detail.backStatus==0?'status0':'status']">{{ detail.payStatusText }}</div>
            <div class="status-icon" />
          </div>
          <div class="order-info">
            <div class="addr">{{ overText(detail.reccity) }}</div>
            <div class="name">{{ overText(detail.recName) }}</div>
          </div>
        </div>
        <div v-if="orderDetailShow" class="detail-item" :class="[detail.tabId == 0 ? 'br8' : '']">
          <div class="item">
            <div class="item-label">寄件信息：</div>
            <div class="item-content">
              <div class="item-content-name">
                {{ detail.sendName }} 
                {{ detail.sendmobile ? detail.sendmobile : detail.sendtel | encryptionPhone(isShowSendPhone) }}
                <img class="item-content-eyes" :src="eyesSrc(isShowSendPhone)" @click="togglePhone('isShowSendPhone')"/>
              </div>
              <div class="contact-addr">{{ clearSign(detail.sendxzq)+detail.sendaddr }}</div>
            </div>
          </div>
          <div class="item">
            <div class="item-label">收件信息：</div>
            <div class="item-content">
              <div class="item-content-name">
                {{ detail.recName }} 
                {{ detail.recmobile ? detail.recmobile : detail.rectel | encryptionPhone(isShowRecPhone)}}
                <img class="item-content-eyes" :src="eyesSrc(isShowRecPhone)" @click="togglePhone('isShowRecPhone')"/>
              </div>
              <div class="contact-addr">{{ clearSign(detail.recxzq)+detail.recaddr }}</div>
            </div>
          </div>
          <div v-if="detail.gotaddr" class="item">
            <div class="item-label">取件位置：</div>
            <div class="item-content">{{ detail.gotaddr }}</div>
          </div>
          <div v-if="detail.paystatus=='PAYED'" class="item" @click="toPayDetail(1)">
            <div class="item-label">已付运费：</div>
            <div class="item-content">{{ detail.payedprice }}元</div>
            <div class="item-copy">查看明细</div>
          </div>
          <div v-if="detail.paystatus=='PAYED'" class="item">
            <div class="item-label">付费方式：</div>
            <div class="item-content">{{ detail.payway }}</div>
          </div>
          <div v-if="detail.valinspay&&detail.valinspay!=0" class="item">
            <div class="item-label">保价金额：</div>
            <div class="item-content">{{ detail.otherValins ? detail.otherValins : detail.valinspay == 0 ? '不保价' : detail.valinspay }}</div>
            <div v-if="detail.tabId >= 5 && detail.tabId != 7" class="item-copy" @click="claim">理赔申请</div>
          </div>
          <div class="item">
            <div class="item-label">物品信息：</div>
            <div class="item-content">{{detail.cargo}}/{{detail.weight || 1}}公斤</div>
          </div>
          <div v-if="detail.comment" class="item">
            <div class="item-label">留言：</div>
            <div class="item-content">{{ detail.comment }}</div>
          </div>
          <div class="item">
            <div class="item-label">订单号：</div>
            <div class="item-content">{{ detail.expid }}</div>
            <div class="item-copy" @click="copyExpid(detail.expid)">复制</div>
            <div class="copytxt" style="position: fixed;top:-9999px;left: -9999px;">{{detail.expid}}</div>
          </div>
          <div class="item" v-if="detail.servicetype">
            <div class="item-label">服务类型：</div>
            <div class="item-content">{{ detail.servicetype }}</div>
          </div>
          <div class="item">
            <div class="item-label">下单时间：</div>
            <div class="item-content">{{ detail.created }}</div>
          </div>
          <div class="item">
            <div class="item-label">下单来源：</div>
            <div class="item-content">{{ detail.source }}</div>
          </div>
        </div>
        <div v-if="orderDetailShow" class="order-expand" @click="orderDetailShow = !orderDetailShow">
          <div>点击收起订单详情</div>
          <img src="https://cdn.kuaidi100.com/images/rebuild/image/collapse.png" />
        </div>
        <div v-if="!orderDetailShow && detail.tabId != 0" class="order-expand" @click="orderDetailShow = !orderDetailShow">
          <div>点击查看订单详情</div>
          <img src="https://cdn.kuaidi100.com/images/rebuild/image/expand.png" />
        </div>
      </div>
    </div>
    <div class="btnstatus">
      <button
        v-if="(detail.tabId == 1 || detail.tabId == 2) && detail.newversion == 'Y'"
        @click="goCancel"
      >取消订单</button>
      <button
        v-if="detail.tabId == 3 && detail.disdoortime < 0"
        @click="toComplain"
      >{{ detail.complaint == 'N' ? '投诉' : '投诉进度' }}</button>
      <button
        v-if="detail.tabId == 3 && detail.disdoortime < 0"
        @click="urgeOrder"
      >催单</button>
      <button
        v-if="detail.tabId === 3 && ((detail.disdoortime >= 0 ) || (detail.modifyable !== 'Y' && detail.disdoortime < 0))"
        @click="bindTelTap(mktInfo.phone)"
      >联系快递员</button>
      <!-- <button
        v-if="detail.modifyable !== 'Y' && detail.kuaidiNum && detail.paystatus == 'PAYED'"
        @click="toShare"
      >分享单号</button> -->
      <button
        v-if="detail.modifyable !== 'Y' && detail.kuaidiNum && detail.paystatus == 'PAYED'"
        @click="reSend('sendAgain')"
      >再下一单</button>
      <button
        v-if="(detail.tabId == 7 || detail.tabId == 4) && detail.complainable ==' Y' && detail.costcomplain=='N'"
      >费用申诉</button>
      <button
        v-if="(detail.tabId == 7 || detail.tabId == 4) && detail.complainable == 'Y' && detail.costcomplain=='N'"
      >申诉进度</button>
      <button
        v-if="(detail.tabId == 4 || detail.tabId == 7) && detail.complainable != 'Y'"
        @click="reSend"
      >重新下单</button>
    </div>
  </div>
</template>

<script>
import Modal from "components/Modal/Modal"
import util from "lib/util"
import { mapGetters } from 'vuex'
import encryptPhoneMixin from 'lib/encryptPhoneMixin'

export default {
  name: 'dispatchDetailIndex',
  mixins: [encryptPhoneMixin],
  components: {
    Modal,
  },
  data() {
    return {
      mobileReg: /^1\d{10}$/,
      showRss: true,
      bannerPos: '',
      isShowLoading: false,
      auth: 1,
      isShowLast: false,
      showComs: false,
      evalStatus: 0,
      sendNameChange: '',
      expid: '',
      dispatchid: '',
      openBest: '0',
      detail: {},
      isMoreOpt: false,
      gInterval: null,
      statusTips: '',
      courierinfo: null,
      waitTime: '', // 等待时间
      showWaitTime: false,
      mktInfo: null,
      orderDetailShow: false,
      modifySendAddr: {},
      isShowCodeTips: false,
      showBigCode: false,
      evaData: {},
      evaData2: {},
      evaAry: [{
        v: '穿着/谈吐不得体',
        s: false
      }, {
        v: '服务态度恶劣',
        s: false
      }, {
        v: '没有按时上门',
        s: false
      }, {
        v: '多/乱收费',
        s: false
      }, {
        v: '乱回填上门时间',
        s: false
      }, {
        v: '电话索要取件码',
        s: false
      }],
      erInput: '',
      ec: [],
      eci: [],
      contactEditor: {
        hasModify: false,
        show: false,
        type: '',
        title: '',
        detail: {},
        dialog: false
      },
      availableCom4BrandList: [],
      chooseComIndex: [],
      dispatchinfo: {},
      adslist: [],
      // #ifdef MP-BAIDU || MP-WEIXIN
			popPos: '',
      // #endif
    }
  },
  created () {
    console.log('1')
    this.login(() => {
      this.expid = this.$route.query.expid
      this.dispatchid = this.$route.query.dispatchid
      this.getOrderInfo(1)
    })
  },
  computed: {
    ...mapGetters([
      'userinfo'
    ]),
    isShowRss () {
      return JSON.stringify(this.detail) !== '{}' && this.detail.tabId != 4 && this.detail.tabId != 7 && this.detail.tabId != 6 && !this.detail.bdsub && this.showRss
    }
  },
  methods: {
    clone (obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    overText (str, num = 6) {
      if (str && str.length > num) {
        return str.substring(0, num) + '...'
      } else {
        return str
      }
    },
    chooseDefault() { // 默认选中指派的快递公司
      let chooseComIndex = []
      if (this.detail.tabId >= 3 && this.detail.kuaidiCom) {
        for (let i = 0; i < this.availableCom4BrandList.length; i++) {
          if (this.detail.kuaidiCom === this.availableCom4BrandList[i].kuaidiCom) {
            chooseComIndex.push(i)
            this.chooseComIndex = chooseComIndex
          }
        }
      } else {
        for (let i = 0; i < this.availableCom4BrandList.length; i++) {
          if (this.detail.comable.indexOf(this.availableCom4BrandList[i].kuaidiCom) > -1) {
            chooseComIndex.push(i)
            this.chooseComIndex = chooseComIndex
          }
        }
      }
    },
    chooseCom(item, index) { // 选择快递公司
      let chooseComIndex = this.chooseComIndex
      if (chooseComIndex.indexOf(index) === -1) {
        chooseComIndex.push(index)
      } else {
        chooseComIndex.splice(chooseComIndex.indexOf(index), 1)
      }
      this.chooseComIndex = chooseComIndex
    },
    claim(url) { // 查看计费明细
      location.href = "https://m.kuaidi100.com/app/frame/claim.jsp"
    },
    toEva() {
      const detail = this.detail
      if (detail.iseval === '1' && detail.evalmsg === '满意') {
        this.$toast('订单已评价，无需再次评价')
      } else if (detail.iseval === '1') { // 不满意显示评价内容
        this.evaAry = detail.evaluateInfo
        this.evalStatus = 3
      } else {
        this.evalStatus = this.evalStatus == 1 || this.evalStatus == 2 ? 0 : 1
      }
    },
    setEvaComments(index) { // 评价不满意的原因
      const i = index
      const ec = this.ec || []
      const eci = this.eci || []
      const evaAry = this.evaAry
      if (eci.indexOf(i) !== -1) {
        eci.splice(eci.indexOf(i), 1)
        ec.splice(eci.indexOf(i).v, 1)
        evaAry[i].s = false
      } else {
        eci.push(i)
        ec.push(evaAry[i].v)
        evaAry[i].s = true
      }
      this.ec = ec
      this.eci = eci
      this.evaAry = evaAry
    },
    evaReasonInput(e) { // 输入不满意的原因
      this.erInput = e.detail.value.replace(/,/g, '，')
    },
    subBadEva() { // 评价 点击完成
      const ec = this.ec
      const erInput = this.erInput || ''
      if (ec.length === 0 && !erInput) {
        this.$toast('请选择您不满意的原因')
        return
      }
      if (erInput) ec.push(erInput)
      this.subEva(0, ec)
      this.evalStatus = 0
    },
    subEva(evaFlag, comments) {
      const url = '/apicenter/order.do?method=evaluateTxtCourier'
      const params = {
        data: {
          expid: this.expid,
          dispatchid: this.dispatchid,
          flag1: evaFlag,
          comments: comments
        }
      }
      this.$http.post(url, params).then((res) => {
        this.evalStatus = 0
        this.$toast(evaFlag == 1 ? '感谢您的评价，期待再次为您服务' : '感谢您的反馈，我们会更加努力提升服务')
        setTimeout(() => {
          this.getOrderInfo()
        }, 1500)
      })
    },
    orderEva(flag) {
      if (flag === '1') {
        this.subEva(1, '')
        this.evalStatus = 0
      } else {
        this.evalStatus = 2
      }
    },
    closeEva() {
      this.evalStatus = 0
      const evaAry = this.evaAry
      for (const i of evaAry) {
        if (i.v) {
          i.s = false
        }
      }
      this.evaAry = evaAry
    },
    orderShared(kuaidiNum) {
      const url = '/apicenter/xcx.do?method=savesharenum'
      const params = {
        num: kuaidiNum
      }
      this.$http.post(url, params).then((res) => {})
    },
    toPayDetail(index = 1) {
      const paywayen = this.detail.paywayen || ''
      const optBtn = index
      this.$router.push({
        name: "dispatchPayDetail",
        query: {
          expid: this.expid,
          dispatchid: this.dispatchid,
          paystatus: this.detail.paystatus,
          paywayen: paywayen,
          optBtn: optBtn
        }
      })
    },
    toQueryNu(com, nu) { // 查询物流信息
      if(!nu) {
        return
      }
      let phone = this.detail.sendmobile ? this.detail.sendmobile.substring(7, this.detail.sendmobile.length) : ''
      location.href = `https://m.kuaidi100.com/result.jsp?nu=${nu}&com=${com}&lastfour=${phone}`
    },
    urgeOrder() {
      const url = '/apicenter/order.do?method=reminder'
      const params = {
        data: {
          expid: this.expid
        }
      }
      this.$http.post(url, params).then((res) => {
        this.$toast('已发送催单请求')
        setTimeout(() => {
          clearInterval(this.gInterval)
          this.getOrderInfo()
        }, 2500)
      })
    },
    toComplain() {
      this.$confirm({
        content: "投诉和查看投诉进度功能需要下载快递100APP，是否前往下载？",
        confirmText: "是",
        cancelText: "否"
      }, () => {
        util.downApp()
      })
    },
    bindTelTap (num) {
      window.location.href = `tel:${num}`
    },
    goUrl(url) {
      location.href = "https://m.kuaidi100.com/help"
    },
    reSend(type) {
      console.log('type', type)
      const detail = this.detail
      const sendxzq = detail.sendxzq ? detail.sendxzq.split('#') : ''
      const sendman = type === 'modify' ? this.modifySendAddr : {
        name: detail.sendName,
        phone: detail.sendmobile,
        fixedPhone: detail.sendtel,
        address: detail.sendaddr,
        xzqName: `${detail.sendxzq.split('#').join(',')}`
      }
      const recxzq = detail.recxzq ? detail.recxzq.split('#') : ''
      const recman = {
        name: detail.recName,
        phone: detail.recmobile,
        fixedPhone: detail.rectel,
        address: detail.recaddr,
        xzqName: `${detail.recxzq.split('#').join(',')}`
      }
      localStorage.setItem('dispatch_sendman', JSON.stringify(sendman))
      if (type !== 'sendAgain') {
        localStorage.setItem('dispatch_recman', JSON.stringify(recman)) // sendAgain = 再下一单
        if (detail.tabId === 4 || detail.tabId === 7) {
          const params = {
            cargo: detail.cargo,
            weight: detail.weight
          }
          localStorage.setItem('dispatch_orderData', JSON.stringify(params))
        }
      } else {
        localStorage.removeItem('dispatch_recman')
        localStorage.removeItem('dispatch_orderData')
      }
      this.$router.replace({
        name: "dispatch",
        query: {
          orderSource: 'sendAgain'
        }
      })
    },
    goCancel() { // 取消订单
      this.$broadcastChannel.$on("dispatchDetail", () => {
        this.getOrderInfo()
      })
      this.$router.push({
        name: "cancel",
        params: {
          event: "dispatchDetail",
          order: this.detail
        }
      })
    },
    copyExpid(str) { // 复制
      const range = document.createRange();
      range.selectNode(document.querySelector(".copytxt"));
      const selection = window.getSelection();
      if(selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
      this.$toast('复制成功')
    },
    copyExpid1(str) { // 复制
      const range = document.createRange();
      range.selectNode(document.querySelector(".copytxt1"));
      const selection = window.getSelection();
      if(selection.rangeCount > 0) selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('Copy');
      this.$toast('复制成功')
    },
    clearSign(str) {
      if (str) {
        return str.replace(/#/g, ' ')
      }
    },
    checkCom(index, arr) {
      return arr.indexOf(index) > -1
    },
    getOrderInfo(tag) { // 订单详情
      const url = '/apicenter/order.do?method=getOrderInfo'
      const params = {
        data: {
          dispatchid: this.dispatchid,
          expid: this.expid
        }
      }
      this.$http.post(url, params).then((res) => {
        this.detail = res.detail
        this.mktInfo = res.mktInfo
        this.dispatchinfo = res.dispatchinfo
        this.courierinfo = res.courierinfo
        if (this.detail.iseval == 0 && this.detail.tabId >= 3 && this.detail.paystatus === 'PAYED' && tag == 1) {
          this.evalStatus = 1
        }
        this.setOrderStutas()
      })
    },
    waitContact () {
      const min = parseInt(this.detail.waittime / 60)
      // 计算响应时间
      const serviceTime = this.dispatchinfo.serviceTimeNew || ''
      const sh = Number(serviceTime.substring(0, 2))
      const eh = Number(serviceTime.substring(6, 8))
      const nowH = new Date().getHours()
      if (min > 10 && nowH < eh && nowH > sh) this.statusTips = '快递员比较繁忙，请稍后'
      else if (nowH >= eh) this.statusTips = '预计联系时间明天' + sh + '：00'
      else if (nowH < sh) this.statusTips = '预计联系时间今天' + sh + '：00'
      else {
        this.statusTips = '预计10分钟内联系'
        this.showWaitTime = true
      }
      this.timeCountStart()
    },
    setOrderStutas() {
      const detail = this.detail
      const tabId = detail.tabId
      const transStatusName = detail.transStatusName || '暂无物流信息'
      switch (tabId) {
        case (1): {
          this.waitContact()
          break
        }
        case (2): {
          this.waitContact()
          break
        }
        case (3): {
          if (detail.filldoortime == 0) this.statusTips = '待回填约定取件时间'
          else this.statusTips = '预约上门时间：' + detail.doorTime
          break
        }
        case (10): {
          this.statusTips = '取件时间：' + detail.gottime
          // 支付状态
          if (!detail.payStatusText && +detail.payedprice > 0) {
            detail.payStatusText = '已支付' + detail.payedprice + '元'
          }
          detail.disdoortime = -1
          break
        }
        case (5): {
          this.statusTips = '在途中：' + transStatusName
          // 支付状态
          if (!detail.payStatusText && +detail.payedprice > 0) {
            detail.payStatusText = '已支付' + detail.payedprice + '元'
          }
          break
        }
        case (6): {
          this.statusTips = '已签收' + transStatusName
          // 支付状态
          if (!detail.payStatusText && +detail.payedprice > 0) {
            detail.payStatusText = '已支付' + detail.payedprice + '元'
          }
          break
        }
        case (4): {
          this.statusTips = '取消时间：' + detail.canceltime
          detail.statusDesc = '取消原因:' + detail.cancelmsg
          // 支付状态
          if (detail.backamount == 0 && detail.payedprice > 0 && !detail.payStatusText) {
            detail.payStatusText = '退款中'
            detail.backStatus = 0
          } else if (detail.payedprice > 0 && !detail.payStatusText) detail.payStatusText = '已退' + detail.backamount + '元'
          break
        }
        case (7): {
          this.statusTips = '取消时间：' + detail.canceltime
          detail.statusDesc = '取消原因:' + detail.cancelmsg
          // 支付状态
          if (detail.backamount == 0 && detail.payedprice > 0 && !detail.payStatusText) detail.payStatusText = '退款中'
          else if (detail.payedprice > 0 && !detail.payStatusText) detail.payStatusText = '已退' + detail.backamount + '元'
          break
        }
        case (12): {
          this.statusTips = '查看改派后的订单 >'
          break
        }
      }
      if (tabId === 5 || tabId === 6 || tabId === 10) {
        this.getEvaList()
      }
      if (detail.gotaddr2) detail.gotaddr2 = detail.gotaddr2.replace(/\。|\，|\.|\,|/g, '')
      this.detail = detail
    },
    getEvaList() {
      const url = '/apicenter/order.do?method=evallist'
      this.$http.post(url).then((res) => {
        this.evaData = res.data
        this.evaData2 = res.data2
      })
    },
    timeCountStart() { // 计算等待时间
      const detail = this.detail
      let waitTime = parseInt(detail.waittime)
      this.gInterval = setInterval(() => {
        waitTime = parseInt(waitTime + 1)
        let min = parseInt(waitTime / 60)
        if (min < 10) min = '0' + min
        let s = waitTime % 60
        if (s < 10) s = '0' + s
        const time = min + ':' + s
        this.waitTime = time
      }, 1000)
    },
    changeCity(str) {
      if (['北京市', '天津市', '上海市', '重庆市'].indexOf(str) > -1) {
        return str.replace('市', '')
      } else {
        return str
      }
    },
    reOrder() { // 重新下单
    }
  }
}
</script>

<style lang="scss" scoped>
  .content {
    display: flex;
    align-self: baseline;
    font-size: 0.8125rem;
    margin-left: 0.3125rem;
  }
  @mixin common-block {
    width: 94.6%;
    background: #fff;
    border-radius: 0.25rem;
    margin: 0.625rem auto 0;
  }
  .select-item::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 1.25rem;
    margin-top: -0.1875rem;
    display: block;
    width: 0.375rem;
    height: 0.375rem;
    border-top: 0.09375rem solid #c4c4c4;
    border-right: 0.09375rem solid #c4c4c4;
    transform: rotate(45deg);
  }
  /*订单信息*/
  .detail {
    background: #efeff4;
    height: 100%;
    overflow-y: scroll;
    .btnstatus {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      background: #efeff4;
      border-top: 1px solid #efeff4;
      z-index: 80;
      padding: 0.625rem 0.25rem;
      button {
        text-align: center;
        flex: 1;
        margin: 0 0.4375rem;
        font-size: 1.0625rem;
        color: #333;
        line-height: 3rem;
        border-radius: 0.25rem;
        background: #ffffff;
        border: 1px solid #ddd;
        overflow: unset;
        padding-left: 0;
        padding-right: 0;
        &:last-child {
          background: #ff7f02;
          border: none;
          color: #ffffff;
        }
      }
    }
  }
  .br8{
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  .order-card {
    margin: 10px 10px 0;
    background: #ffffff;
    height: 5.125rem;
    border-radius: 0.125rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
.code-image {
  img {
    width: 74%;
    margin-left: 13%;
    margin-bottom: 16px;
  }
}
.order-info {
  text-align: center;
}

.order-info .addr {
  font-size: 1.0625rem;
  color: #333;
}

.order-info .name {
  font-size: 0.875rem;
  color: #888;
  margin-top: 0.6875rem;
}

.order-status {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.order-status .status {
  font-size: 0.875rem;
  color: #888;
  display: flex;
  align-items: baseline;
}
.order-status .status0{
  font-size: 0.875rem;
  color: #ff7f02;
  display: flex;
  align-items: baseline;
}
.order-status .status-icon {
  position: relative;
  background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACIAAAAfCAYAAAH1pC8nAAAAAXNSR0IArs4c6QAAA/xJREFUWAmtVk1oE0EUdrdb21iNXqwIjQhSaL2oGLQNJSYVRKg/FQQRPagXexBBL8WD4KGHXjx4EEFE8CAIYquCoAiNTZsiEumpreJF8aAiWGltk5im6/e2eduXySTdlQ4k8733vvfNm9nZ2VmzptiGh4cPMTYTicQpMgqFwit2GgSGhoZs12EYPxmXBBxnMpncx1GTwMLCwiWkXyVsSB1yOA2jTjMu6Ymty3BLMgzjmW3bm5EVoUzYT9DZjY2NZwzIHobRFI/H7wH3g9gLwizsIJHLGkh5DFcoC0gHSF+lTdiZJzsxTBNU7rPtEDSzOC8JvDDPUSAE7OMUBH6J7g9+cxY50I4huITwD0wzXGpyCMLSJobZ2dnpLFyRT/LLUkTgAPdYqBJfSTaTqJdDqaNIHuESRSU4ybYUZJ/szZGRkV3FSX6WAczprrSrCbm7SCb4xRb2Qz0/Ezl3+E/C/1gKhkKh+ubm5pz0EaY1WY/Sb0uBIqmn2DsdOCGdAAU9PZ2ampoDaEkp6hnjqAh5JuuIWJMknkhKF1N92ulAoAWLOkVkrMUL7NAjaqK0tZutrq7uC5Mg1sW4Uq8ViUQiGZmAyvqlrWKTdiJIg2pA2qimV9oqdioBqXtsbCygBqWNgc5KW2I6F6chsgnOHBaRjhhqcNndS3D5H3HnQC16bNiJWCx2h0QGkHBimeoPmaZ5gaaz1V9aKRsFvKaDcT+5Udoj7IfTTFFffcS/I64d0ISSs+GkAAspfatiu6aJEfrwgm1zPRoATh6D/NaEHJeF4PVKQfbX1tbuYazrtTs2lUrtkOSOjo4JaatYK7K4uPiLiZhKnLHvnnZwOp2u9ZKoPQoqJZLozMwMfaO3CM48qt2Ltf0gfL6hdkkqqWQyGTotZRFEXYffJC5JO8n43+ZrRWgQ8c6rY/61LGt7NBr9pga82PT5s7C0R/ELNDQ0PA2Hw/MrJL5H/KCGsxY3xanR0dEQduasJl7VRW9wF3bnAK6uD/H851BY2WkmFVAwFVKpbczn8xNeN6gUMYPB4BvpQGGDKKZP+iReoRA6l0OYUFrmeMHOHsHA4xDY7SVhtTmYWBrf2ii/NR9XewCveliAcC6Xu+jcoWHE1ERUKg9keSu2wN+g8jU2nYp56MhcByOfvprz6N7h43ADt69P9NbQPcY9G0CYDgQCrW1tbT804nTBvgz/LV2MfdC4hgOu6i2EudzTq9uOQthO4tseg891cED0YYHLIHJv+i2CRHwfaFgR+nppT1EU8QBFnCNhv81XIfQRy2az2gMPRax4Pa1WHL811ThuDIdVi2sIgCLeYiWq3pEFXQt9FYLdPY5Be6DkrApwFr8rKKJdq+7D+Q8Ja51sAS9ceAAAAABJRU5ErkJggg==') center no-repeat;
  background-size: 1.0625rem 0.9375rem;
  width: 1.0625rem;
  height: 1.0625rem;
}

.order-status .status-icon::after {
  content: '';
  position: absolute;
  width: 3.375rem;
  height: 1px;
  background-color: #efeff4;
  left: 1.375rem;
  top: 0.5rem;
}

.order-status .status-icon::before {
  content: '';
  position: absolute;
  width: 3.375rem;
  height: 1px;
  background-color: #efeff4;
  right: 1.375rem;
  top: 0.5rem;
}

.order-expand {
  display: flex;
  font-size: 0.8125rem;
  color: #888;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.9375rem;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  background: #fff;
  margin: 0 10px;

}
.order-expand img {
  width: 0.8125rem;
  height: 0.8125rem;
  margin-left: 0.3125rem;
}
.detail-item {
  background: #fff;
  border-top: 1px solid #eee;
  margin: 0 10px;
}

.detail-item .item {
  position: relative;
  display: flex;
  flex-direction: row;
  margin-top: 0.625rem;
}
.detail-item .item:first-child{
  margin-top: 1.0625rem
}
.detail-item .item:last-child {
  padding-bottom: 0.625rem;
}

.detail-item .item-label {
  font-size: 0.875rem;
  margin-left: 1.1875rem;
  color: #888;
  width: 4.6875rem;
}

.detail-item .item .item-content {
  font-size: 0.875rem;
  line-height: 1.25rem;
  max-width: 15.625rem;
  img {
    width: 1rem;
    height: 1rem;
    margin-left: 0.4rem;
  }
}

.detail-item .item .item-copy {
  font-size: 0.875rem;
  color: #3581eb;
  margin-left: 0.625rem;
}
.contact-addr{
  color: #888;
}
.more-opt {
  display: flex;
  position: absolute;
  right: 0;
  top: 0;
  width: 2.9375rem;
  height: 2.625rem;
  justify-content: center;
  align-items: center;
}

.more-opt img {
  width: 1.375rem;
  height: 1.375rem;
}

.more-btn {
  position: absolute;
  width: 6.25rem;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 0.25rem;
  font-size: 0.875rem;
  color: #fff;
  text-align: center;
  right: 0.625rem;
  top: 2.1875rem;
  z-index: 111;
}

.more-btn .item {
  height: 3rem;
  line-height: 3rem;
}

.more-btn:last-child {
  border-bottom: 0;
}

.more-btn .ico-guide {
  position: absolute;
  margin-top: -0.15625rem;
  border-width: 0 0.3125rem 0.3125rem;
  border-color: transparent transparent rgba(0, 0, 0, 0.6);
  z-index: 101;
  margin-left: 5.125rem;
  border-style: solid;
  top: -0.1875rem;
}
/*评价modal*/

.efloat {
  animation: efloatfadeIn 0.2s;
}

.modalFade {
  animation: fadeIn 0.2s;
}

@-webkit-keyframes fadeIn {
  0% {
    bottom: -15.625rem;
  }
  100% {
    bottom: 0;
  }
}

@-webkit-keyframes efloatfadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.efloat-bg {
  position: absolute;
  width: 100%;
  height: 100%;
}

.emodal-title {
  position: relative;
  width: 100%;
  height: 2.75rem;
  line-height: 2.75rem;
  text-align: center;
  font-size: 1.0625rem;
  color: #333;
  display: flex;
  justify-content: center;
}

.modal-tcancle {
  position: absolute;
  width: 3.125rem;
  font-size: 0.9375rem;
  color: #888;
  left: 0.9375rem;
}

.modal-tcomplete {
  font-size: 0.9375rem;
  color: #3581eb;
  position: absolute;
  width: 3.125rem;
  right: 0.9375rem;
}

.emodal-eva {
  justify-content: conter;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #eee;
}

.emodal-eva .title {
  font-size: 1.0625rem;
  color: #333;
  text-align: center;
  margin-top: 2.4375rem;
}

.emodal-eva .eva-desc {
  font-size: 0.8125rem;
  color: #888;
  margin-top: 0.25rem;
  text-align: center;
}

.emodal-eva .eva-items {
  display: flex;
  justify-content: center;
  margin-top: 2.3125rem;
  padding-bottom: 3.0625rem;
  font-size: 1.0625rem;
  color: #888;
}

.emodal-eva .eva-items .eva-item {
  display: flex;
  align-items: center;
  height: 1.875rem;
  line-height: 1.875rem;
}

.emodal-eva .eva-items .eva-item:last-child {
  padding-left: 2.5rem;
}

.eva-item view {
  font-size: 0.9375rem;
  background-color: #e4e4e4;
  border-radius: 0.9375rem;
  padding: 0.5rem 0.625rem 0.5rem 1.25rem;
  height: 0.9375rem;
  line-height: 0.9375rem;
  color: #888;
  position: relative;
  right: 0.9375rem;
}

.emodal-eva .eva-items  img {
  position: relative;
  width: 2.75rem;
  height: 2.75rem;
  z-index: 2;
}

.emodal-close {
  top: 0.9375rem;
  right: 0.9375rem;
}

.eva-bad {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2.0625rem;
  margin-top: 1rem;
}

.eva-bad img {
  position: relative;
  width: 2rem;
  height: 2rem;
  z-index: 2;
}

.eva-bad view {
  background-color: #e4e4e4;
  padding: 0.3125rem 0.625rem 0.3125rem 1.25rem;
  font-size: 0.625rem;
  color: #888;
  position: relative;
  right: 0.9375rem;
  border-radius: 0.625rem;
}

.evaAry {
  display: flex;
  margin-top: 1.6875rem;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.evaAry-title {
  font-size: 1.0625rem;
  color: #333;
  text-align: center;
  margin-top: 0.375rem;
}

.evaAry-item {
  min-width: 8.75rem;
  min-height: 0.875rem;
  font-size: 0.875rem;
  border-radius: 0.1875rem;
  border: 1px solid #eee;
  text-align: center;
  margin-bottom: 0.9375rem;
  color: #878787;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.3125rem;
  margin-left: 1.5625rem;
}
  .ei-selected {
    border: 1px solid #3581eb;
    color: #3581eb;
  }
  .eva-input {
    border: 1px solid #eee;
    width: 19.6875rem;
    height: 1.875rem;
    margin-left: 1.5625rem;
    margin-bottom: 2.1875rem;
    padding-left: 0.625rem;
    font-size: 0.875rem;
  }
  /*取件码*/
  .gcode-box {
    margin: 0 10px;
    background-color: #fff;
    margin: 0.625rem 0.625rem 0 0.625rem;
    border-radius: 0.25rem;
  }

  .code-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 3.5rem;
    align-items: center;
  }

  .code-info .nu-value {
    display: flex;
    align-items: center;
  }

  .nu-value .label {
    font-size: 0.875rem;
    color: #888;
    margin-left: 0.9375rem;
  }

  .code-value {
    font-size: 1.25rem;
    color: #333;
    letter-spacing: 0.9375rem;
    width: 15.9375rem;
    text-align: center;
  }
  .code-img {
    display: flex;
    justify-content: center;
    height: 2.3125rem;
    padding-bottom: 1.25rem;
    img {
      width: 16.75rem;
      height: 2.3125rem;
    }
  }
  .code-help {
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAMAAAAM7l6QAAAAilBMVEUAAADAwMDS0tK/v7++vr6+vr6/v7/CwsLNzc3AwMC/v7/AwMC/v7+/v7+/v7+/v7+/v7/AwMDBwcHCwsLDw8PFxcW/v7+/v7+/v7++vr6/v7+/v7/BwcHCwsLMzMy/v7+/v7+/v7+/v7+/v7+/v7+/v7/BwcHBwcHDw8O+vr6/v7/AwMC/v7++vr7s9odLAAAALXRSTlMAUQPs6eO2GgpZj2r70aF4Z2AyKxIO9NnCnYBEIh8Iy7ytpJaISzgnFvNyQKjjV4i7AAABVUlEQVQoz2VT17aDMAwzTcIou5RdNpQu///v3VNILobqJXaEFCs5AEFsvca8CMoT/OIUtkl39sNiyNjnfmQ1N9NMWRuB6OYdO6YabWufT8R4QPPgduHWf+1lv9PcUPm9GqnVvEa041Xq2WWdhBnLavaIToLI3itvZcuSj2t7RvGu4aajkHr3a1+xemkiJ6mWqVMMVrp0ASDo18ZGT7kMMtD3VF1Tu74qPJXIBuAPeVdzvGq4+g7CHE7OIfMLEymASYdK7NkAMVR15MKD7Vgbne027w3AzvyOqJFn7ACEQegMC3pQDtDb5OkSvBFatwA0fesjpJOYzxggZsamflwJHS5C/0PcqZgvL3oVF7VTsnSTF53c5GqenOSyxFVVT0PGTltTZXYiUPDFDHvYu4QTO8ekjXS3Aopq4Oe3nNjquV3DAXPRcFf32vSp28SJwLhMYRnRP+IPN0MWj+Qc2B0AAAAASUVORK5CYII=') center no-repeat;
    background-size: 0.9375rem;
    width: 2.5rem;
    height: 0.9375rem;
  }
  .image-show{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #444444;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  .image-show img {
    width: 100%;
  }
  /*订单状态*/

  .ostatus-box {
    border-radius: 0.25rem;
  }

  .cover-map {
    margin-top: -5rem;
    z-index: 99;
  }

  .ostatus-card {
    position: relative;
    padding: 1.875rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    margin: 0.625rem 10px 0 0.625rem;
    border-radius: 0.25rem;
    .card-title {
      font-size: 1.3125rem;
      color: #333;
    }
    .card-tips {
      font-size: 0.875rem;
      color: #888;
      margin-top: 0.625rem;
    }
    .card-tips.blue {
      color: #317ee7;
    }
    .card-desc {
      font-size: 0.875rem;
      color: #333;
      margin-top: 0.625rem;
      padding: 0 0.9375rem;
      text-align: center;
    }
    .card-time {
      font-size: 0.875rem;
      color: #333;
      margin-top: 0.375rem;
    }
  }
  .bbr0 {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  .time {
    color: #ff7f02;
  }

  /*快递员信息*/
  .cour-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 4.6875rem;
    background-color: #fff;
    margin: 0 10px;
    border-top: 1px solid #efeff4;
    align-items: center;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
  .bb0 {
    border-bottom: 0;
  }
  .info-left {
    display: flex;
    height: 3.375rem;
    align-items: center;
  }

  .info-avatar {
    margin-left: 0.9375rem;
  }

  .info-avatar img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
  }

  .info-detail {
    margin-left: 0.9375rem;
  }

  .info-detail .name {
    font-size: 1.0625rem;
    color: #333;
  }

  .info-detail .d-service {
    font-size: 0.6875rem;
    color: #888;
    margin-top: 0.3125rem;
  }

  .info-detail .d-service span {
    margin-left: 0.625rem;
  }

  .info-tel {
    margin-right: 0.9375rem;
  }

  .info-tel img {
    width: 1.375rem;
    height: 1.375rem;
  }

  /*按钮*/

  .btn-box {
    position: fixed;
    height: 3rem;
    z-index: 99;
    background-color: #efeff4;
    width: 100%;
    padding: 0.625rem 0 0.9375rem 0;
    bottom: 0;
  }

  .btn-box .cancle-btn {
    width: 94.6%;
    height: 3rem;
    background-color: #fff;
    margin-left: 0.625rem;
    font-size: 1.0625rem;
    color: #888;
    line-height: 3rem;
    text-align: center;
    border: 1px solid #ddd;
  }

  .btn-box .btn-strong {
    width: 94.6%;
    height: 3rem;
    background-color: #ff7f02;
    margin-left: 0.625rem;
    font-size: 1.0625rem;
    color: #fff;
    line-height: 3rem;
    text-align: center;
  }

  .got-btns {
    width: 94.6%;
    display: flex;
    justify-content: space-between;
    margin-left: 0.625rem;
    font-size: 1.0625rem;
    line-height: 3rem;
    text-align: center;
  }

  .btn-n-s {
    width: 10.625rem;
    height: 3rem;
    border: 1px solid #ddd;
    color: #888;
    background-color: #fff;
    position: relative;
    border-radius: 0.1875rem;
  }

  .btn-s-s {
    width: 10.625rem;
    height: 3rem;
    border: 1px solid #ff7f02;
    color: #fff;
    background-color: #ff7f02;
    border-radius: 0.1875rem;
  }

  .btn-n-se {
    width: 6.875rem;
    height: 3rem;
    border: 1px solid #ddd;
    color: #888;
    background-color: #fff;
    border-radius: 0.1875rem;
  }

  .btn-s-se {
    width: 6.875rem;
    height: 3rem;
    border: 1px solid #ff7f02;
    color: #fff;
    background-color: #ff7f02;
    border-radius: 0.1875rem;
  }

  /*paybox*/

  .pay-box {
    width: 94.6%;
    height: 5rem;
    margin-top: 0.625rem;
    background-color: #fff;
    margin-left: 0.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .pay-detail {
    margin-left: 0.9375rem;
  }

  .pay-detail .d-num {
    font-size: 1.3125rem;
    color: #333;
  }

  .d-num-w {
    display: flex;
    justify-content: space-between;
    width: 21.25rem;
  }

  .pay-detail .d-num span {
    color: #ff7f02;
  }

  .pay-detail .d-td {
    position: relative;
    font-size: 0.875rem;
    color: #888;
    margin-top: 0.3125rem;
  }

  .d-td::after {
    content: '';
    position: absolute;
    background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAPCAMAAADJev/pAAAAdVBMVEUAAADT09O+vr6/v7/Jycm/v7+/v7+/v7+/v7/AwMC/v7/AwMDCwsK/v7+/v7+/v7+/v7+/v7+/v7++vr6/v7/AwMDAwMDAwMDAwMDBwcHAwMDCwsLBwcG/v7+/v7++vr6+vr7BwcG/v7+/v7/IyMjMzMy+vr6PneG4AAAAJnRSTlMABPf7CunJsod+PTYh39vQuqablpFzaGFaU00nFPLu7HpGMBgXD5MmjVcAAACGSURBVBjTbZDZDoMgFEQHRMWl1VZtbe3qMv//iQY1UcTzwuQEuAs2RBcc8iD1kY8lPd5c33xZ3Ok9974/MRSIKBPbq4CBAnCln269CHl+TSE3YWW9qDLzdMH6upuKzdjNNB+Wc/qb9u2BKnO2b2pnBTWQ+nRWVFHGKJgL7CmZIdEDHMSvHgHlDgi0bdjMVAAAAABJRU5ErkJggg==') right center no-repeat;
    background-color: #fff;
    background-size: 0.75rem 0.4375rem;
    transform: rotate(90deg);
    width: 0.75rem;
    height: 0.4375rem;
    margin-left: 0.3125rem;
    margin-top: 0.375rem;
  }

  .paybtn-s {
    height: 2.1875rem;
    background-color: #ff7f02;
    color: #fff;
    font-size: 1.0625rem;
    text-align: center;
    line-height: 2.1875rem;
    margin-right: 0.625rem;
    padding: 0 0.9375rem;
  }

  .paybtn-tips {
    display: flex;
    align-items: center;
    margin-right: 0.9375rem;
    font-size: 0.8125rem;
    color: #3581eb;
  }

  .paybtn-tips img {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.3125rem;
  }

  /*快递单号卡片*/

  .kdnu-box {
    div {
      display: flex;
      margin: 0.625rem 10px 0;
      line-height: 2.75rem;
      font-size: 1rem;
      color: #3581eb;
      background-color: #fff;
      border-radius: 0.25rem;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 1.125rem;
      height: 1.125rem;
      margin-left: 1rem;
    }
  }

  /*评价*/

  .eva-box {
    background-color: #fff;
    width: 94.6%;
    height: 2.75rem;
    display: flex;
    justify-content: space-between;
    margin-top: 0.625rem;
    margin-left: 0.625rem;
    align-items: center;
    border-radius: 0.25rem;
    position: relative;
  }

  .eva-box .label {
    font-size: 1rem;
    margin-left: 0.625rem;
    color: #888;
  }

  .eva-box .eva-status {
    font-size: 1rem;
    margin-right: 1.875rem;
  }

  .fc3 {
    color: #333;
  }

  .fcb {
    color: #ff7f02;
  }

  .item-t {
    font-size: 1.0625rem;
    color: #3581eb;
  }

  .item-t:last-child {
    margin-left: 4.6875rem;
  }
  .app-ico-close {
    position: absolute;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABwAAAAcCAMAAABF0y+mAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAgVBMVEUAAADJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycnJycny8vLz8/PNzc309PT////19fX29vbOzs739/cAAACyCj+0AAAAIHRSTlMAAT6JxOj5igJi2yHKQOtGRyfy8wNjP8PCy8woSdyLxZ5AedMAAAABYktHRACIBR1IAAAACXBIWXMAAAsSAAALEgHS3X78AAAA5klEQVQoz32T2xaCIBBFR0xKs4tpdtFyChX6/x8MMgSU2i/OYis44xHgS0DCRURptFiSAFxWcYIjSbyy3TpFh3Rt3GaLE7ZEux3FGXQ/uOyAHvJMuSJFL2khZYw/OMr+kl8yCaDU9ePJ1IW1T71SQqjLtuulZbxr9UoIJ10KLi3jn1sGzhChZfnLchiBNQDx6jpuHFJbspk027L5tifL9Uy4L7R0WxFOK9MhiHaU5b/xyTwc/wweiovfXdUngyr3ubwaolD7YlLrENWzZ/PaxK+6Ts6r3FDfjLq5oVaa3Bv1OzR3Mqo3woZMovX/Uh0AAAAldEVYdGRhdGU6Y3JlYXRlADIwMTctMDUtMDlUMTA6MTM6MjQrMDg6MDA4OWzfAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDE3LTA1LTA5VDEwOjEzOjI0KzA4OjAwSWTUYwAAAABJRU5ErkJggg==") center no-repeat;
    background-size: 0.875rem;
    height: 0.875rem;
    width: 0.875rem;
  }
  .modal {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: #fff;
  }
  .modal-code {
    position: absolute;
    top: 8.75rem;
    width: 100%;
    background: #fff;
    padding-bottom: 1.875rem;
  }

  .modal-code .code {
    font-size: 1.625rem;
    letter-spacing: 1rem;
    color: #333;
    text-align: center;
    margin-top: 1.25rem;
  }

  .modal-code .code-img img {
    width: 92%;
    height: 3.0625rem;
    margin-left: 4%;
    margin-top: 0.5rem;
  }

  .modal-code .tips {
    font-size: 0.875rem;
    color: #888;
    text-align: center;
  }

  .modal-code .mt30 {
    margin-top: 0.9375rem;
  }

  .s-remind {
    font-size: 0.875rem;
    color: #f00;
    margin-left: 0.625rem;
  }

  /*banner*/
  .realname-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.75rem;
    margin: 0.625rem 0.625rem 0;
    padding: 0 0.625rem;
    background: #FFF;
    border-radius: 0.25rem;
    color: #333;
    font-size: 0.875rem;
  }
  .realname-box .button {
    width: 5rem;
    background:rgba(255,127,2,1);
    border-radius:0.125rem;
    line-height: 1.6875rem;
    color: #FFF;
    text-align: center;
  }
  .order-card-tip {
    width: 20rem;
    height: 1.5625rem;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.03);
    border-radius: 0.125rem;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #bebebe;
    line-height: 1.5625rem;
    text-align: center;
  }
  .float {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 103;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
  }
</style>
